

body {
  background: #080056;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}

@media only screen and (min-width: 600px) {
  body {
    background: #080056;
  }
}

* {
  font-family: Exo;
  box-sizing: border-box;
}
